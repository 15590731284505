import {PLUS_STATUS, ProductionDailyUserBonus, ProductionUserBonus} from "@/domain/model/productionBonus/bonus.model";
import {
    CreateProductionDailyUserBonus,
    CreateProductionUserBonus, SetProductionDailyUserBonus,
    SetProductionUserBonus
} from "@/domain/command/bonus/bonus.command";
import {http} from "@/domain/service/api/http-api";
import _ from "lodash";
import store from '@/domain/store';
import {User} from "@/domain/model/user/user.model";
import moment from "moment";
import {formatDate} from "@/domain/helper/date.helper";

const sanetizeBonus = (bonus: any): ProductionUserBonus => {
    const user: User = _.cloneDeep(store.getters.userById(bonus.userId));
    return {
        id: bonus.id,
        user: user,
        material: store.getters.materialById(bonus.materialId),
        date: Number((moment(bonus.date).format('X'))),
        amount: bonus.amount,
        amountExpected: bonus.amountExpected,
        status: bonus.status,
        bonusMaterialRange: {
            min: bonus['bonusMaterialRange.min'],
            max: bonus['bonusMaterialRange.max'],
            money: bonus['bonusMaterialRange.money'],
            name: bonus['bonusMaterialRange.name'],
        },
    }
}

const sanitizeDailyBonus = (bonus: any): ProductionDailyUserBonus => {
    const user: User = _.cloneDeep(store.getters.userById(bonus.userId));
    return {
        id: bonus.id,
        user,
        date: Number((moment(bonus.date).format('X'))),
        status: bonus.status,
    }
}

const sanetizeMonthlyBonuses = (bonuses: any): ProductionUserBonus[] => {
    const newBonuses: ProductionUserBonus[] = [];
    bonuses.map((bonus: any) => {
        const user: User = store.getters.userById(bonus.userId);
        newBonuses.push({
            id: bonus.id,
            user: user,
            material: store.getters.materialById(bonus.materialId),
            date: Number((moment(bonus.createdAt).format('X'))),
            amount: bonus.amount,
            amountExpected: bonus.amountExpected,
            status: bonus.status,
            bonusMaterialRange: bonus.bonusMaterialRange
        })
    });
    return newBonuses;
}


export function useBonusApi() {
    const fetchAll = async () => {
        const response: any = await http().get('bonus/');
        response.data.forEach((bonus: any) => {
            const sanetizedBonus = sanetizeBonus(bonus);
            store.commit('saveBonus', sanetizedBonus);
        })
    };

    const fetchById = async (id: string) => {
        const response = await http().get('bonus/' + id);
        const sanetizedBonus = sanetizeBonus(response.data);
        store.commit('saveBonus', sanetizedBonus);

    };
    const fetchAllDaily = async () => {
        const response: any = await http().get('bonus/daily');
        response.data.map((bonus: any) => {
            const sanetizedBonus = sanitizeDailyBonus(bonus);
            store.commit('saveDailyBonus', sanetizedBonus);
        })
    };

    const fetchAllDailyByDate = async (date: number) => {
        const response: any = await http().get('bonus/daily-by-date/' + date);
        const allSanitizeDailyBonuses: ProductionDailyUserBonus[] = [];
        response.data.map((bonus: any) => {
            allSanitizeDailyBonuses.push(sanitizeDailyBonus(bonus));
        })
        store.commit('saveAllDailyBonus', allSanitizeDailyBonuses);
    };

    const fetchAllDailyByDates = async (dateInit: string, dateEnd: string) => {
        // console.log('api daily', Number(dateInit)/1000, Number(dateEnd)/1000);
        const response: any = await http().get('bonus/daily-by-dates/' + Number(dateInit) / 1000 + '/' + Number(dateEnd) / 1000);
        const allSanitizeDailyBonuses: ProductionDailyUserBonus[] = [];
        response.data.map((bonus: any) => {
            allSanitizeDailyBonuses.push(sanitizeDailyBonus(bonus));
        })
        store.commit('saveAllDailyBonus', allSanitizeDailyBonuses);
    };

    const fetchDailyById = async (id: string) => {
        const response = await http().get('bonus/daily/' + id);
        const sanitizedBonus = sanitizeDailyBonus(response.data);
        store.commit('saveDailyBonus', sanitizedBonus);

    };
    const fetchByDate = async (initDate: string, endDate: string) => {
        // console.log('api material',  Number(initDate)/1000, Number(endDate)/1000);
        const response = await http().get('bonus/all-bonuses-between-dates/' + Number(initDate) / 1000 + '/' + Number(endDate) / 1000);
        if (!response.data)
            return;
        if (response.data.length === 0)
            return;
        response.data.map((bonus: any) => {
            const sanetizedBonus = sanetizeBonus(bonus);
            store.commit('saveBonus', sanetizedBonus);
        })
    }

    const createBonus = async (command: CreateProductionUserBonus) => {
        const newCommand = _.cloneDeep(command);
        await http().post('bonus/create', newCommand);

        await fetchById(command.id);
    };
    const createDailyBonus = async (command: CreateProductionDailyUserBonus) => {
        const newCommand = _.cloneDeep(command);
        await http().post('bonus/daily-create', newCommand);

        await fetchDailyById(command.id);
    };
    const setDailyBonus = async (command: SetProductionDailyUserBonus) => {
        const newCommand = _.cloneDeep(command);
        await http().post('bonus/daily-set', newCommand);

        await fetchDailyById(command.id);
    };

    const setBonus = async (command: SetProductionUserBonus) => {
        const newCommand = _.cloneDeep(command);
        await http().post('bonus/create-or-update', newCommand);
        await fetchById(command.id);
    }

    return {
        fetchAll,
        fetchById,
        createBonus,
        setBonus,
        fetchByDate,
        setDailyBonus,
        createDailyBonus,
        fetchAllDaily,
        fetchAllDailyByDate,
        fetchAllDailyByDates,
    };

}